import React from "react"
import styled from "styled-components"

const Title = ({ title }) => {
  return (
    <Wrapper>
      <h2>{title}</h2>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  text-align: center;
  margin-bottom: 2rem;
  h2 {
    display: flex;
    justify-content: center;
    font-weight: 500;
  }
`
export default Title

import React from "react"
import { Link } from "gatsby"
import "../assets/fonts/Savoye_LET_Plain1.0.woff"

const links = ({ styleClass, children }) => {
  return (
    <ul className={styleClass}>
      <li>
        <Link to="/" className="page-link">
          home
        </Link>
      </li>
      <li>
        <Link to="/about" className="page-link">
          about
        </Link>
      </li>
      <li>
        <Link to="/portfolio" className="page-link">
          portfolio
        </Link>
        {children}
      </li>
      <li>
        <Link to="/contact" className="page-link">
          contact
        </Link>
      </li>
    </ul>
  )
}

export default links

import React from "react"
import SocialLinks from "../../constants/SocialLinks"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-item">
        <p>
          &copy;{new Date().getFullYear()} All right reserved by Kaede
          Nakashima.
        </p>
      </div>
      <SocialLinks styleClass="footer-icons" />
    </footer>
  )
}
export default Footer

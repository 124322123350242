import React from "react"
import Image from "gatsby-image"
import { FaRegClock } from "react-icons/fa"
import { IoMdArrowRoundForward } from "react-icons/io"
import { Link } from "gatsby"
import styled from "styled-components"

const Post = ({ frontmatter, excerpt }) => {
  // const { title, image, date, slug, category, readTime } = frontmatter

  return (
    <Wrapper>
      {/* <Image fluid={image.childImageSharp.fluid} className="img" /> */}
      {/* <div className="info"> */}
      {/* <span className="">{category}</span> */}
      {/* <h3>{title}</h3> */}
      {/* <p>{excerpt}</p>
        <Link to={`/posts/${slug}`} className="link">
          Continue Reading <IoMdArrowRoundForward />
        </Link> */}
      {/* <footer> */}
      {/* <span className="date">
            <FaRegClock className="icon" />
            {date}
          </span> */}
      {/* <span>{readTime}min read</span> */}
      {/* </footer> */}
      {/* </div> */}
    </Wrapper>
  )
}

const Wrapper = styled.article`
  /* margin-bottom: 3rem; */
  .info {
    text-align: center;
    text-transform: lowercase;
    padding: 0 20px;
  }
  .info span {
    font-family: ProximaNova-Semibold, helvetica, Arial, sans-serif;
  }
  .img {
    margin-bottom: 1rem;
    border-radius: var(--radius);
    height: 17rem;
  }
  .category {
    display: inline-block;
    margin-bottom: 1rem;
    background: var(--clr-grey-10);
    padding: 0.25rem 0.5rem;
    text-transform: lowercase;
    font-weight: 700;
    border-radius: var(--radius);
    letter-spacing: var(--spacing);
    color: var(--clr-grey-5);
  }
  h3 {
    font-weight: 400;
    margin-bottom: 1rem;
    text-transform: initial;
  }
  .underline {
    width: 5rem;
    height: 1px;
    background: var(--clr-grey-9);
    margin-bottom: 1rem;
  }
  p {
    column-gap: (var--clr-grey-5);
    line-height: 1.8;
    font-family: ProximaNova-Semibold, helvetica, Arial, sans-serif;
  }
  .link {
    background-color: #fff;
    font-family: var(--ff-primary);
    width: 180px;
    text-transform: lowercase;
    color: #383838;
    padding-bottom: 0.1rem;
    display: flex;
    align-items: center;
    letter-spacing: 0;
    svg {
      margin-left: 0.25rem;
      font-size: 1.2rem;
      color: #383838;
    }
  }
  a.link:hover + a.link svg:hover {
    color: #9e9e9e;
  }

  footer {
    margin-top: 2rem;
    padding-top: 1rem;
    /* border-top: 1px solid hsl(0deg 0% 23%); */
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #383838;

    & .date {
      display: flex;
      align-items: center;
      & .icon {
        color: #383838;
        margin-right: 0.5rem;
      }
    }
  }
  @media (min-width: 600px) {
    .img {
      height: 20rem;
    }
  }
  @media (min-width: 800px) {
    .img {
      height: 25rem;
    }
  }
  @media (min-width: 992px) {
    & {
      display: grid;
      grid-template-columns: 30rem 1fr;
      column-gap: 1.5rem;
      .info {
        text-align: left;
      }
      .img {
        height: 100%;
        max-height: 20rem;
      }
      .underline {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
`

export default Post

import React from "react"
import { FaGithubAlt, FaLinkedinIn, FaTwitter } from "react-icons/fa"

const SocialLinks = ({ styleClass }) => {
  return (
    <ul className={styleClass}>
      <li>
        <a href="https://github.com/kaedenakashima">
          <FaGithubAlt className="social-icon github-icon" />
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/in/kaede-n-9b4353148/">
          <FaLinkedinIn className="social-icon linkedin-icon" />
        </a>
      </li>
      <li>
        <a href="https://twitter.com/KaedeNakashima">
          <FaTwitter className="social-icon twitter-icon" />
        </a>
      </li>
    </ul>
  )
}

export default SocialLinks

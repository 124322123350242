import React from "react"
import styled from "styled-components"

const SearchButtons = ({ portfolios, setPortfolios, setBackToAll }) => {
  const [index, setIndex] = React.useState(0)
  const types = [
    // "all",
    ...new Set(
      portfolios.map(portfolio => {
        return portfolio.data.type
      })
    ),
  ]
  const showPortfolios = (type, typeIndex) => {
    setIndex(typeIndex)
    if (type === "all") {
      setBackToAll()
    } else {
      const tempPortfolios = portfolios.filter(
        portfolio => portfolio.data.type === type
      )
      setPortfolios(tempPortfolios)
    }
  }
  return (
    <Wrapper>
      {types.map((type, typeIndex) => {
        return (
          <button
            key={typeIndex}
            className={index === typeIndex ? "active" : undefined}
            onClick={() => showPortfolios(type, typeIndex)}
          >
            {type}
          </button>
        )
      })}
    </Wrapper>
  )
}
const Wrapper = styled.section`
  text-align: center;
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  button {
    text-align: left;
    font-size: 1rem;
    cursor: pointer;
    outline: none;
    transition: var(--transition);
    border: none;
    font-family: Baskervville, sans-serif;
    margin: 0.1rem;
    background: #eeeeef;
    color: #333333;
    border-radius: 4px;
    padding: 2px 10px 2px;
    width: 150px;
  }
  button:hover,
  button.active {
    box-shadow: 0px 1.5px 0 var(--clr-grey-6);
    background: #cccccc;
  }
  @media (min-width: 576px) {
    display: flex;
    margin-bottom: 0;
    flex-wrap: wrap;
    width: 780px;
    text-align: center;
    margin: 0 auto;

    button {
      margin: 0.25rem;
      background: #eeeeef;
      border: 1px solid #5e5e5e;
      color: #333333;
      border-radius: 4px;
      text-align: left;
      font-size: 1rem;
      cursor: pointer;
      outline: none;
      transition: var(--transition);
      border: none;
      padding: 3px 10px 3px;
      width: auto;
      font-family: "Baskervville", sans-serif;
    }
  }
`
export default SearchButtons

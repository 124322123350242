import React from "react"
import styled from "styled-components"
import { Button } from "@material-ui/core"
import { HeroBanner } from "../../utils"
import img from "../../assets/images/1054.png"
import img2 from "../../assets/images/1054-color.png"

const HomeHeader = () => {
  return (
    <BannerWrapper>
      <div className="ttl-container">
        <h2 className="ttl">
          Hi. I'm Kaede Nakashima. <br />
          A front end developer. <br />
        </h2>
        <Button className="btn" variant="contained" color="primary">
          <span className="btn-txt">
            <i>See Sample</i>
          </span>
        </Button>
        {/* <div class="banner-img">
         <img src={img} />
        <img src={img2} />
        </div>  */}
      </div>
    </BannerWrapper>
  )
}

const BannerWrapper = styled.div`
  .ttl-container {
    padding: 9em 0 0;
    text-align: center;
    min-height: calc(100vh - 27rem);
    background: #fefefe;
  }
  .ttl {
    font-size: 40px;
    line-height: 1.2em;
    color: rgb(0 0 0 / 77%);
    font-family: "Savoye LET", sans-serif;
  }
  .banner-img {
    display: flex;
    justify-content: flex-end;
  }
  .btn {
    background-color: #9e9e9e38;
    margin-top: 20px;
    padding: 5px 30px;
    box-shadow: none;
    font-family: var(--ff-primary);
  }
  .btn:hover {
    background-color: #9e9e9e38;
    box-shadow: none;
  }
  .btn-txt {
    font-weight: 500;
    font-size: 16px;
    text-transform: initial;
    color: #383838;
  }
  .icon {
    vertical-align: middle;
    padding-left: 8px;
    font-size: 20px;
  }
  @media (min-width: 992px) {
    .ttl-container {
      padding: 17em 0 0;
    }
  }
`

export default HomeHeader

import React from "react"
import { Link } from "gatsby"
import Title from "./Title"
import styled from "styled-components"
import Image from "gatsby-image"
import SearchButtons from "./SearchButtons"

const Portfolio = ({ portfolios: data, title, page, icon, details }) => {
  const [portfolios, setPortfolios] = React.useState(data)
  const setBackToAll = () => {
    setPortfolios(data)
  }
  return (
    <Wrapper className="section">
      {page && (
        <SearchButtons
          portfolios={data}
          setPortfolios={setPortfolios}
          setBackToAll={setBackToAll}
        />
      )}
      <div className="section-center">
        {portfolios.map(item => {
          const { id } = item
          const { title, type, url, details, projectType, icon } = item.data
          const fluid = item.data.image.localFiles[0].childImageSharp.fluid

          return (
            <article key={id}>
              <div className="img-container">
                <Image fluid={fluid} className="img" alt={title} />
                <a
                  href={url}
                  className="link-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Demo
                </a>
              </div>
              <div className="caption">
                <div className="txt">
                  <h3>{title}</h3>
                  <span>{projectType}</span>
                  {details}
                </div>
              </div>
            </article>
          )
        })}
      </div>
      {/* {!page && (
        <Link to="/portfolio" className="btn">
          all portfilio
        </Link>
      )} */}
    </Wrapper>
  )
}

const Wrapper = styled.section`
  .section-center {
    max-width: var(--max-width);
    display: grid;
    gap: 2rem;
    text-align: center;
    margin: 4rem 16px;
    grid-gap: 2rem;
    .img {
      height: 10rem;
      transition: var(--transition);
    }
    article {
      transition: var(--transition);
    }
    article:hover {
    }
    .img-container {
      position: relative;
      overflow: hidden;
      background: hsl(0deg 0% 0%);
      border: 1px solid black;
      &:hover .img {
        opacity: 0.2;
      }
      &:hover .link-btn {
        opacity: 1;
      }
      .link-btn {
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        letter-spacing: 0rem;
        color: #fff;
        border: 1px solid #fff;
        padding: 0.5rem 0.7rem;
        display: inline-block;
        transition: all 0.3s linear;
        cursor: pointer;
        font-family: Arial, Helvetica, sans-serif;
      }
    }

    .caption {
      text-align: left;
      background: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: Arial, Helvetica, sans-serif;
      h3 {
        font-size: 16px;
        margin-bottom: 0;
        font-weight: 400;
        color: rgb(0 0 0 / 77%);
        font-family: ProximaNova-Semibold, helvetica, Arial, sans-serif;
        border-bottom: 1px solid rgb(0 0 0 / 77%);
        margin: 15px 0 10px;
      }
      .txt {
        font-size: 12px;
      }
      .txt span {
        border-radius: 2px;
        padding: 2px 3px;
        margin: 0 5px 0 0;
        border: 1px solid #333;
      }
    }
    @media (min-width: 400px) {
      grid-template-columns: 1fr 1fr;
      .img {
        height: 10rem;
      }
    }
    @media (min-width: 992px) {
      grid-template-columns: 2fr 1fr 1fr 1fr;
      .img {
        height: 10rem;
      }
    }
    @media (min-width: 1200px) {
      .img {
        height: 10rem;
      }
    }
  }

  a {
    display: block;
    width: 9rem;
    text-align: center;
    margin: 0 auto;
    margin-top: 3rem;
  }
  @media (min-width: 768px) {
    .section-center {
      margin: 4rem 16px;
    }
  }
  @media (min-width: 992px) {
    .section-center {
      margin: 4rem auto;
      padding: 0 45px;
    }
  }
  @media (min-width: 1200px) {
    .section-center {
      margin: 4rem auto;
      padding: 0;
    }
  }
`
export default Portfolio

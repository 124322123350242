import styled from "styled-components"
import React from "react"
import img from "../assets/images/3122.jpg"
import img2 from "../assets/images/OCRT1X0.jpg"
import { ImageCrop32 } from "material-ui/svg-icons"

function MainPageHeader({ img, children }) {
  return <IndexHeader img={img}>{children}</IndexHeader>
}

function PageHeader({ img2, children }) {
  return <DefaultHeader img2={img2}>{children}</DefaultHeader>
}

const IndexHeader = styled.header`
  min-height: calc(100vh - 68px);
  /* background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(${props => props.img}) center/cover fixed no-repeat; */
  display: flex;
  justify-content: center;
  align-items: center;
`
const DefaultHeader = styled(IndexHeader)`
  min-height: 60vh;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(${props => props.img2}) center/cover fixed no-repeat;
`

MainPageHeader.defaultProps = {
  img: img,
}
PageHeader.defaultProps = {
  img2: ImageCrop32,
}

export { MainPageHeader, PageHeader }
